import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import Layout from "../components/layout/layout"

import useIsClient from '../useIsClient';

export default function NotFound() {

  const { isClient, key } = useIsClient();

  useEffect(() => {
    if (window.location.pathname.includes('/products/starterpaket') || window.location.pathname.includes('/deinDrop') || window.location.pathname.includes('/deindrop')) {
      navigate('/products/monatspaket/');
    }
  }, []);

  if ( !isClient ) return null;

  return (
    <Layout key={key} showBottom="false">
      <div style={{ paddingTop: '100px'}} className="container mb-5">
        <h1>Seite nicht gefunden</h1>
        <p>Ups, wir konnten diese Seite nicht finden!</p>
      </div>
    </Layout>
  )
}
